<template>
  <div class="wrap">
    <div class="money-box">
      余额：
      <h3 class="num">{{ s_getUser.volley }}</h3>
      <span class="coin"></span>
    </div>
    <ul class="money-ul flex-w">
      <li class="list" @click="cIndex = index" :class="{ active: cIndex == index, first: m.is_first == 1 }" v-for="(m, index) in list">
        <p class="price">
          {{ m.money }}
          <span class="coin"></span>
        </p>
        <p class="txt" v-if="m.reward_money">送{{ m.reward_money }}金币</p>
        <!-- <p class="txt" v-if="m.reward_noble_name">送{{ m.reward_noble_day }}天{{ m.reward_noble_name }}</p> -->
        <p class="money">￥{{ m.price }}</p>
      </li>
    </ul>
    <payList @setPay="setPay"></payList>
    <div class="bottom flex-c"><el-button round class="app-btn" @click="submit()">立即充值</el-button></div>
  </div>
</template>

<script>
import payList from '@components/common/pay';
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    payList
  },
  data() {
    return {
      list: [],
      cIndex: 0
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
    this.getData();
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {
    // ...mapMutations([]),
    setPay(t) {},
    async getData() {
      let { data } = await this.$y_ajax('api/Topup/lists', { user_id: this.$y_getKey('userid') });
      this.list = data;
    },
    async submit() {
      // let { data } = await this.$y_ajax('api/service/CreateOrder', { id: 34 });
      // this.des = data.body;
      this.$y_msg('该服务暂未开放');
    }
  },
  computed: {
    // 计算属性
    ...mapGetters(['s_getUser'])
  },
  watch: {
    // 监听
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;

  .money-box {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .num {
      font-size: 25px;
      font-weight: normal;
      color: #fd5621;
      margin: 0 10px;
    }
  }
}
.money-ul {
  position: relative;
  width: 100%;

  .list {
    border: 1px solid #eee;
    border-radius: 5px;
    text-align: center;
    width: calc((100% - 90px) / 4);
    margin-right: 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 20px 0 10px;
    border-radius: 7px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    &:nth-child(4n) {
      margin-right: 0;
    }

    &.first::before {
      display: inline-block;
      content: '';
      background-image: url('../../assets/recharge_label_discount.png');
      width: 64px;
      height: 20px;
      background-size: 100%;
      position: absolute;
      top: -10px;
      left: -1px;
    }

    &.active {
      border: 1px solid #fd5621;
      &::after {
        display: inline-block;
        content: '';
        background-image: url('../../assets/recharge_select.png');
        width: 20px;
        height: 20px;
        background-size: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .price {
      font-size: 1rem;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .txt {
      font-size: 0.5rem;
      color: #fd5621;
    }

    .money {
      color: #555;
    }
  }
}

.coin {
  display: inline-block;
  content: '';
  background-image: url('../../assets/recharge_icon_gold.png');
  width: 20px;
  height: 20px;
  background-size: 100%;
  margin-left: 5px;
}
.app-btn {
  background: #fd5621;
  border: 0;
  color: #fff;
  margin-top: 30px;
  width: 200px;
  padding: 18px;
  border-radius: 30px;
}
/* 移动端 */
@media (max-width: 600px) {
  .money-ul {
    .list {
      width: calc((100% - 15px) / 2);
      margin-right: 15px;
      margin-bottom: 15px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
