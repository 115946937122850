<template>
    <ul class="pay">
        <li class="list" :class="{ active: pay == 2 }" @click="setPay(2)">
            <i class="iconfont icon-zhifubaozhifu" style="color: #009fe8;"></i>
            <span>支付宝</span>
        </li>
        <li class="list" :class="{ active: pay == 3 }" @click="setPay(3)">
            <i class="iconfont icon-weixin" style="color: #43c93e;"></i>
            <span>微信支付</span>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'pay-list',
        data() {
            return {
                pay: 2
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            setPay(type) {
                this.pay = type;
                this.$emit('setPay', type);
            }
        },
        computed: {},
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .pay {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 30px 0;

        .list {
            list-style: none;
            border: 1px solid #eee;
            width: 30%;

            border-radius: 7px;
            padding: 17px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover,
            &.active {
                color: #fd5621;
                border-color: #fd5621;
            }

            .iconfont {
                font-size: 30px;
                margin-right: 15px;
            }
        }
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .pay {
            .list {
                width: 45%;
                padding: 10px 0;
            }
        }
    }
</style>